import React, { Component } from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'
// import { head as _head } from 'lodash'
import moment from 'moment'

import {
    Row, Col, 
    Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next } from '@sckit/gatsby-plugin-i18next'
// import Moment from 'react-moment'

import Layout from '../components/layout'
import NewsMenu from '../components/news/news_menu'
import NewsList from '../components/news/news_list'
import FilterDropdown from '../components/filter-dropdown'

import './../css/news.css'

// if (typeof window !== `undefined`) {
//   window.newsPostsToShow = 8
// }

class NewsPage extends Component {
    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        // let postsToShow = 8
        // let _pts = 8
        // if (typeof window !== `undefined`) {
        //     postsToShow = window.newsPostsToShow
        //     _pts = window.newsPostsToShow
        // }
        this.state = {
            allLocaleData: this._useData(`all`),
            filter: {
                type: 'all',
                year: null,
                month: null,
                txtYear : '',
                txtMonth : '',
            },
            listYear : {},
            listMonth : {},
            label : {},
            // showingMore: postsToShow > _pts,
            postsToShow:4,
            postsToLoad:2,
        }
    }

    componentDidMount() {
        let self = this
        // console.log(`news-mount`)
        window.addEventListener(`scroll`, this._handleScroll)

        let t = JSON.parse(self.props.data.locales.edges[0].node.data)
        
        let listyear = []
        let listmonth = {}
        for (let i=new Date().getFullYear(); i >=2017; i-- ) 
            listyear.push(i)
        for (let i=205,j=1; i <=216; i++,j++ ) 
            listmonth[j] = t[`PT0${i}`]
        // console.log(`listyear : `, listyear )
        // console.log(`listmonth : `, listmonth )
        this.setState({
            listYear:listyear,
            listMonth:listmonth,
            label : { 
                'year': t['PT0203'] , 
                'month': t['PT0204'],  
            },
            filter: { 
                type: 'all',
                txtYear : t['PT0203'], 
                txtMonth : t['PT0204']  
            },
        })

        if (typeof window !== 'undefined') {
            const urlHash = window.location.hash.split('#')
            console.log(`urlHash - ${urlHash}`)
            const newsTypeSelected = (urlHash.length <= 1) ? 'all' : urlHash[1]
            this._setFilter({type: newsTypeSelected})
            
            let mq = document.getElementsByClassName('wrapper')[0].offsetWidth
            console.log(`news-mount - mq = ${mq}`)
            if (mq>1138) this.setState({ postsToShow: 8, postsToLoad: 4 })
            else if (mq>718) this.setState({ postsToShow: 6, postsToLoad: 3 })
            else this.setState({ postsToShow: 4, postsToLoad: 2 })
        }

    }

    componentWillUnmount() {
        window.removeEventListener(`scroll`, this._handleScroll)
        // window.newsPostsToShow = this.state.postsToShow
    }

    _useData(newsType) {
        const allNewsData = this.props.data || []
        if (newsType !== "all") newsType = `type`+(newsType.substring(0,1).toUpperCase())+newsType.substring(1, newsType.length)
        const _d = allNewsData[`${newsType}`].edges.map(e => e.node)
        return _d
    }

    _setFilter(options) {
        // console.log(`==== _setFilter START / options `, options)
        options = options || {}

        if (options !== {}) {

            const dd = this._useData(options.type)
            let _dd = []
            let checking = (options.year > 2000 || options.month > 0)

            if (checking) {
                _.map( dd, o => {
                    let yy = moment(o.head.datetime).year()
                    let mm = moment(o.head.datetime).month()+1  //Correct month for checking

                    if (( options.year && options.month && yy === options.year && mm === options.month ) ||
                        ( options.year && !options.month && yy === options.year ) ||
                        ( !options.year && options.month && mm === options.month )){
                            console.log(`o.head.datetime = ${o.head.datetime} / year = ${yy} | ${options.year} , month = ${mm} | ${options.month} / o = `, o)
                            _dd.push(o)
                        }
                })
            } 
            _dd = ( checking===true && _dd.length >= 0 ) ? _dd : dd

            this.setState({
                allLocaleData: _dd,
                filter: options,
            })
        }
    }

    handleFilter = (ek,mode) => {
        // console.log(`ek = ${ek} | mode = ${mode} | this.state.filter.year = ${this.state.filter.year} | this.state.filter.month = ${this.state.filter.month} , state = `, this.state)
        ek = +ek
        let fdate = {}
        switch (mode){
            case 'newsyear':
                fdate = (ek===0) ?  { year : ek, txtYear: this.state.label.year } : { year : ek, txtYear: `${ek}` }
                if (this.state.filter.month > 0){
                    fdate.month = this.state.filter.month
                    fdate.txtMonth = this.state.filter.txtMonth
                }
                break;
            case 'newsmonth':
                fdate =  (ek===0) ?  { month : ek, txtMonth: this.state.label.month } : { month : ek, txtMonth: `${this.state.listMonth[ek]}` }
                if (this.state.filter.year > 2000){
                    fdate.year = this.state.filter.year
                    fdate.txtYear = this.state.filter.txtYear
                }
                break;
            default:
        }
        // console.log(`set fdate = `, fdate)
        fdate.type = this.state.filter.type
        
        this._setFilter( fdate )
    }


    _update() {
        // console.log(`news-update`)
        // const distanceToBottom = (document.querySelector('main').offsetTop + document.querySelector('main').offsetHeight) * 0.7
        // const distanceToBottom = window.innerHeight * 0.8
        const distanceToBottom = document.querySelector('#footer .base').offsetTop
        let newsType = this.state.filter.type
        newsType = (newsType !== "all") ? `type`+(newsType.substring(0,1).toUpperCase())+newsType.substring(1, newsType.length) : newsType
        // console.log(`d to btm: ${distanceToBottom} < w.sY: ${window.scrollY} / ${window.scrollY+window.innerHeight} && pts: ${this.state.postsToShow} < ${this.props.data[newsType].totalCount} `)
        if (distanceToBottom < window.scrollY+window.innerHeight && this.state.postsToShow < this.props.data[newsType].totalCount){
            this.setState({ postsToShow: this.state.postsToShow + this.state.postsToLoad })
        }
        this.ticking = false
    }

    _handleScroll = () => {
        // console.log(`news-scrollhandle`)
        if (!this.ticking) {
            this.ticking = true
            requestAnimationFrame(() => this._update())
        }
    }



    render() {
        const news = this.state.allLocaleData
        const enNews = this.props.data.allNewsEngExclusive.edges.map(e => e.node)
        // const lang = this.props.lng


        // DEBUG :
        // console.log(` ==== this.props : news list page ==== `, this.props )
        // console.log(`news = `, news)
        // console.log(`enNews = `, enNews)
        // console.log(`this.state.postsToShow = `, this.state.postsToShow)
        
        return (
            <NamespacesConsumer>

            {(t, { i18n }) => {
                

                // Init data
                const ftypelist = {
                    "all":      [ t('PT2001'), '#all' ],
                    "news":     [ t('PT2002'), '#news' ],
                    "events":   [ t('PT2003'), '#events' ],
                    "other":    [ t('PT0202'), '#other' ],
                }

                

                // console.log(`----- this.state : `, this.state)
                // console.log(`-----ftypelist : `, ftypelist)
    

                return (
                    <Layout siteData={ { title:`${t("MU000")} | ${t("MU003")}`, url:this.props.location.href } }>
                        <div id="NewsList" className="body-page ">
                            <nav role="navigation"> 
                                <Container className="wrapper line-head">
                                    <div className="phead">{t('MU003')}</div>
                                </Container>
                                <Container className="wrapper line-bottom">
                                    <Row>
                                        <Col xs={12} md={8} lg={8}>
                                            {
                                                (this.state.filter.type) ?
                                                <NewsMenu active={this.state.filter.type} ftypelist={ftypelist} setFilter={this._setFilter.bind(this)} />
                                                    :
                                                    null
                                            }
                                        </Col>
                                        <Col xs={6} md={2} lg={2} className="d-flex align-items-center pr-0 pl-md-0 pb-3 pb-md-0">
                                            {
                                                <FilterDropdown mode="newsyear" seltext={this.state.filter.txtYear} trans={this.state.trans} 
                                                filterlist={this.state.listYear} handleFilter={this.handleFilter.bind(this)} />
                                            }
                                        </Col>
                                        <Col xs={6} md={2} className="d-flex align-items-center pl-0 pb-3 pb-md-0">
                                            {
                                                <FilterDropdown mode="newsmonth" seltext={this.state.filter.txtMonth} trans={this.state.trans} 
                                                filterlist={this.state.listMonth} handleFilter={this.handleFilter.bind(this)} />
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                            </nav>

                            <main role="main" className="main">
                                <Container className="wrapper">
                                {
                                    (news) ?
                                        (news.length>0)?
                                            <Row className="plist plistsquare d-flex justify-content-start flex-wrap">
                                            {news.map((newsPost, i) => {
                                                // console.log(`i = ${i} pts=${this.state.postsToShow} `)
                                                let hs = (i<this.state.postsToShow) ? ' show ' : 'hidden'
                                                return (
                                                    <NewsList key={`newlist-card-${i}`} news={newsPost} enNews={enNews} ftypelist={ftypelist} hs={hs}/>
                                                )
                                            })}
                                            </Row>
                                            :
                                            <Row className="error msg-block"> {t("ER002")} </Row>
                                        :
                                        <Row className="loading msg-block"></Row>
                                }
                                </Container>
                            </main>
                        </div>

                    </Layout>
                )
            }}
            </NamespacesConsumer>
        )
    }
}

export default withI18next()(NewsPage)

export const query = graphql`
    query getNewsLang($lng: String!, $lngReg: String!) {
        locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
            ...LocaleFragment
        }
        
        all: allPrismicNews( filter: { lang: { regex: $lngReg }, data: {type: { ne: "codex" }} }, sort: { fields: data___datetime, order: DESC }) {
            edges {
                node {
                    ... on PrismicNews {
                        id: prismicId
                        lang
                        alternate_languages {
                            id
                            lang
                        }
                        head: data {
                            datetime
                            title {
                            text
                            }
                            thumbnail {
                                url
                            }
                            type
                        }
                        raw: dataString
                    }
                }
            }
            totalCount
        }

        typeNews: allPrismicNews( filter: { lang: { regex: $lngReg }, data: { type: { eq: "news" } } }, sort: { fields: data___datetime, order: DESC }) {
            edges {
                node {
                    ... on PrismicNews {
                        id: prismicId
                        lang
                        alternate_languages {
                            id
                            lang
                        }
                        head: data {
                            datetime
                            title {
                                text
                            }
                            thumbnail {
                                url
                            }
                            type
                        }
                        raw: dataString
                    }
                }
            }
            totalCount
        }

		typeEvents: allPrismicNews( filter: { lang: { regex: $lngReg }, data: { type: { eq: "events" } } }, sort: { fields: data___datetime, order: DESC }) {
            edges {
                node {
                    ... on PrismicNews {
                        id: prismicId
                        lang
                        alternate_languages {
                            id
                            lang
                        }
                        head: data {
                            datetime
                            title {
                                text
                            }
                            thumbnail {
                                url
                            }
                            type
                        }
                        raw: dataString
                    }
                }
            }
            totalCount
        }

		typeOther: allPrismicNews( filter: { lang: { regex: $lngReg }, data: { type: { eq: "other" } } }, sort: { fields: data___datetime, order: DESC }) {
            edges {
                node {
                    ... on PrismicNews {
                        id: prismicId
                        lang
                        alternate_languages {
                            id
                            lang
                        }
                        head: data {
                            datetime
                            title {
                                text
                            }
                            thumbnail {
                                url
                            }
                            type
                        }
                        raw: dataString
                    }
                }
            }
            totalCount
        }

        allNewsEngExclusive: allPrismicNews(filter: { lang: { eq: "en-gb" }, data: {type: { ne: "codex" }} }, sort: { fields: data___datetime, order: DESC }) {
            edges {
                node {
                    id: prismicId
                    data { title { text } }
                }
            }
        }
    }
`
