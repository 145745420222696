import React, { Component } from 'react'
// import { Link } from 'gatsby'
import _ from 'lodash'

import {
    Nav
} from 'react-bootstrap'

import { withNamespaces } from 'react-i18next'



class NewsMenu extends Component {
    // constructor(props) {
    //     super(props)
    //     // Don't call this.setState() here!
    //     // this.state = {
    //     // }
    // }

    render() {

        // console.log(` ==== this.props : NewsMenu ====  `, this.props )

        // const t = this.props.t
        // const lang = this.props.lng
        // const mode = this.props.mode
        const ftypelist = this.props.ftypelist

        return(
            <Nav className="tabMenu" bsstyle="pills" defaultActiveKey={this.props.active}>
            {
                _.map(ftypelist, (data,key) =>{
                    return(
                        <Nav.Link key={`tabmenu-filter-${key}`} eventKey={key} 
                            active={(this.props.active === key) ? true : false} href={`${data[1]}`} onClick={() => this.props.setFilter({type: key }) }>{data[0]}</Nav.Link>
                    )
                })
            }
            </Nav>
        )


    }
}

export default withNamespaces()(NewsMenu)
